import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";
import MaybeClassProps from "../MaybeClassProps.type";

const Card: FunctionComponent<MaybeClassProps> = ({ className, children }) => {
  return (
    <div
      className={mergeClassNames("rounded-lg p-4 z-10 my-4 md:my-0", className)}
    >
      {children}
    </div>
  );
};
export default Card;
