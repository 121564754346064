import React, { FunctionComponent } from "react";
import "./Abbr.scss";
type Props = {
  term: string;
  title: string;
  className?: string;
};
const Abbr: FunctionComponent<Props> = ({ term, title }) => {
  return (
    <>
      <abbr title={title}>{term}</abbr>={title}
    </>
  );
};
export default Abbr;
