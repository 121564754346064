import React, { FunctionComponent } from "react";
import AssetCard from "../../components/AssetCard/AssetCard";
import { graphql, useStaticQuery } from "gatsby";
import AssetCardProps from "../../components/AssetCard/AssetCard.type";

const UnmetNeeds: FunctionComponent<AssetCardProps> = ({
  className,
  cta,
  tracking,
}) => {
  const data = useStaticQuery(graphql`
    query {
      unmetNeedsFlashcard: file(
        relativePath: { eq: "hepatitis-b-resources/unmet-needs-flashcard.pdf" }
      ) {
        publicURL
      }
    }
  `);

  return (
    <AssetCard
      className={className || "lg:w-1/3"}
      to={data.unmetNeedsFlashcard.publicURL}
      headline="Unmet Needs in Chronic Hepatitis B Flashcard"
      content="Considerations for managing aging chronic hep B patients and comorbidities."
      linkText={cta || "View the Flashcard"}
      tracking={tracking}
    />
  );
};
export default UnmetNeeds;
