import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../helpers";

type Props = {
  color?: string;
  className?: string;
  size?: string;
};
const Strong: FunctionComponent<Props> = ({
  children,
  className,
  color,
  size,
}) => {
  const textColor = `text-${color || "black"}`;
  const textSize = `text-${size || "md"}`;
  return (
    <strong
      className={mergeClassNames(
        "leading-tight font-bold antialiased",
        textColor,
        textSize,
        className
      )}
    >
      {children}
    </strong>
  );
};

export default Strong;
