import React, { FunctionComponent } from "react";
import AssetCard from "../../components/AssetCard/AssetCard";
import MaybeClassProps from "../../components/MaybeClassProps.type";
import Paragraph from "../../components/Paragraph";
import AssetCardProps from "../../components/AssetCard/AssetCard.type";

const MultilingualEducation: FunctionComponent<AssetCardProps> = ({
  className,
  tracking,
}) => {
  return (
    <AssetCard
      className={className || "lg:w-1/3"}
      external
      isGilead
      to="https://www.hepb.com"
      headline="Multilingual patient education"
      content={
        <>
          <Paragraph>
            Visit HepB.com, a Gilead patient education website about hepatitis
            B, to download the <em>Living with Hepatitis B</em> brochure in a
            variety of languages.
          </Paragraph>
          <Paragraph>
            Share it with patients seeking additional information about CHB.
          </Paragraph>
        </>
      }
      linkText="Visit Website"
      tracking={tracking}
    />
  );
};
export default MultilingualEducation;
