import React, { FunctionComponent } from "react";
import AssetCard from "../../components/AssetCard/AssetCard";
import { useStaticQuery, graphql } from "gatsby";
import AssetCardProps from "../../components/AssetCard/AssetCard.type";

const AfricanInfographic: FunctionComponent<AssetCardProps> = ({
  className,
  cta,
  tracking,
}) => {
  const data = useStaticQuery(graphql`
    query {
      africanPatientInfographic: file(
        relativePath: {
          eq: "hepatitis-b-resources/african-patient-chronic-hep-b-infographic.pdf"
        }
      ) {
        publicURL
      }
    }
  `);
  return (
    <AssetCard
      className={className || "lg:w-1/3"}
      to={data.africanPatientInfographic.publicURL}
      headline="African Patient Chronic Hepatitis B Infographic"
      content="An overview of the impact of chronic hep B on African immigrant populations."
      linkText={cta || "View the Infographic"}
      tracking={tracking}
    />
  );
};
export default AfricanInfographic;
