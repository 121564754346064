import React, { FunctionComponent } from "react";
import MaybeClassProps from "./MaybeClassProps.type";
import { mergeClassNames } from "../helpers";

const Footnotes: FunctionComponent<MaybeClassProps> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassNames(className, "text-xs text-grey-dark pt-2")}>
      {children}
    </div>
  );
};

export default Footnotes;
