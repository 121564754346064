import React, { FunctionComponent } from "react";
import AssetCard from "../../components/AssetCard/AssetCard";
import { graphql, useStaticQuery } from "gatsby";
import AssetCardProps from "../../components/AssetCard/AssetCard.type";

const AsianInfographic: FunctionComponent<AssetCardProps> = ({
  className,
  cta,
  tracking,
}) => {
  const data = useStaticQuery(graphql`
    query {
      asianPatientInfographic: file(
        relativePath: {
          eq: "hepatitis-b-resources/asian-patient-chronic-hep-b-infographic.pdf"
        }
      ) {
        publicURL
      }
    }
  `);

  return (
    <AssetCard
      className={className || "lg:w-1/3"}
      to={data.asianPatientInfographic.publicURL}
      headline="Asian Patient Chronic Hepatitis B Infographic"
      content="An overview of the impact of chronic hep B on Asian populations."
      linkText={cta || "View the Infographic"}
      tracking={tracking}
    />
  );
};
export default AsianInfographic;
