import { Link } from "gatsby";
import { mergeClassNames } from "../../helpers";
import React, { FunctionComponent, SVGProps, useState } from "react";

import { ReactComponent as DefaultIcon } from "./default-icon.svg";
import Interstitial from "../Interstitial";

import "./AssetCard.scss";

type Props = {
  to: string;
  external?: boolean;
  isGilead?: boolean;
  headline: string | JSX.Element;
  content: string | JSX.Element;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  linkText: string | JSX.Element;
  className?: string;
  tracking: Function;
};

const AssetCard: FunctionComponent<Props> = ({
  to,
  external,
  isGilead,
  headline,
  content,
  icon: Icon,
  linkText,
  className,
  tracking,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const htmlAttributes = () => {
    const attributes: {
      className: string;
    } = {
      className: mergeClassNames(
        "transition-transform hover:-translateY-3px",
        "mt-6 lg:mt-0",
        "ab-test-card",
        className
      ),
    };

    return attributes;
  };

  const renderTag = (children: JSX.Element) => {
    if (external) {
      return (
        <>
          <a href={to} onClick={showModal} {...htmlAttributes()}>
            {children}
          </a>
          {modalOpen && (
            <Interstitial to={to} isGilead={isGilead} onCancel={hideModal} />
          )}
        </>
      );
    } else {
      return (
        <a href={to} {...htmlAttributes()} download onClick={tracking}>
          {children}
        </a>
      );
    }
  };

  return renderTag(
    <div className="px-4 h-full lg:py-4">
      <div className="AssetCard bg-white shadow p-6 flex h-full">
        <div className="AssetCard__Icon mr-8">
          {Icon ? <Icon /> : <DefaultIcon />}
        </div>
        <div className="flex flex-col w-full">
          <h4 className="AssetCard__Headline">{headline}</h4>
          <div className="AssetCard__Content mt-4 flex-grow text-black-dark">
            {content}
          </div>
          <span className="AssetCard__Link text-green underline font-medium font-bold mt-6 block">
            {linkText}
          </span>
        </div>
      </div>
    </div>
  );

  function showModal(event: React.MouseEvent) {
    tracking();
    setModalOpen(true);
    event.preventDefault();
    return false;
  }
  function hideModal(event: React.MouseEvent) {
    setModalOpen(false);
    event.preventDefault();
    return false;
  }
};

export default AssetCard;
