import React, { FunctionComponent } from "react";
import line from "./line.svg";
import lineWhite from "./line-white.svg";
import { mergeClassNames } from "../../helpers";
import { useInView } from "react-intersection-observer";
import "./Subheading.scss";

type Props = {
  className?: string;
  level?: number;
  mobileCenter?: boolean;
  lineColor?: string;
  pad?: boolean;
};

const Subheading: FunctionComponent<Props> = ({
  children,
  level,
  className,
  lineColor,
  mobileCenter,
  pad,
}) => {
  const mobileItems = mobileCenter ? "items-center" : "items-start";
  const mobileText = mobileCenter ? "text-center" : "text-left";

  const { ref, inView } = useInView({ threshold: 1, triggerOnce: true });
  const lineClass = ["line"];
  if (inView) {
    lineClass.push("visible");
  }

  return (
    <hgroup
      className={mergeClassNames(
        "flex flex-col md:items-start",
        mobileItems,
        className
      )}
    >
      <img
        ref={ref}
        className={lineClass.join(" ")}
        src={lineColor === "white" ? lineWhite : line}
        role="presentation"
        alt=""
      />
      {level === 3 ? (
        <h3 className={`pt-4 ${pad ? "md:pt-8" : ""} ${className} ${pad}`}>
          {children}
        </h3>
      ) : (
        <h2
          className={mergeClassNames(
            "pt-2 md:text-left",
            mobileText,
            className
          )}
        >
          {children}
        </h2>
      )}
    </hgroup>
  );
};

export default Subheading;
