import React, { FunctionComponent } from "react";
import AssetCard from "../../components/AssetCard/AssetCard";
import { useStaticQuery, graphql } from "gatsby";
import AssetCardProps from "../../components/AssetCard/AssetCard.type";

const LivingWithHepBBrochure: FunctionComponent<AssetCardProps> = ({
  className,
  headline,
  content,
  tracking,
}) => {
  const data = useStaticQuery(graphql`
    query {
      livingWithHepBBrochure: file(
        relativePath: {
          eq: "hepatitis-b-resources/living-with-hep-b-brochure.pdf"
        }
      ) {
        publicURL
      }
    }
  `);

  return (
    <AssetCard
      className={className || "lg:w-1/3"}
      to={data.livingWithHepBBrochure.publicURL}
      headline={headline || "Living with Hepatitis B—English"}
      content={
        content ||
        "An overview of hepatitis B for your patients, from transmission methods to long-term management (English version)."
      }
      linkText="Download Patient Brochure"
      tracking={tracking}
    />
  );
};
export default LivingWithHepBBrochure;
