import React, { FunctionComponent } from "react";
import ButtonBase from "./ButtonBase";

import ButtonProps from "./ButtonProps.type";

const ButtonSecondary: FunctionComponent<ButtonProps> = props => {
  const { children, className } = props;

  return (
    <ButtonBase
      {...props}
      className="transition-bg transition-color bg-white shadow-button text-black hover:bg-black hover:text-white"
    >
      {children}
    </ButtonBase>
  );
};

export default ButtonSecondary;
