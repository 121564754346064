import { mergeClassNames } from "../../helpers";
import React, { FunctionComponent } from "react";

import ButtonProps from "./ButtonProps.type";
import Link from "../Link/Link";

const ButtonBase: FunctionComponent<ButtonProps> = props => {
  const {
    className,
    to,
    children,
    external,
    isGilead,
    openInNewTab,
    tracking,
    jump,
  } = props;

  const htmlAttributes = () => {
    const attributes: {
      target?: string;
      rel?: string;
      className: string;
      external?: boolean;
      isGilead?: boolean;
      tracking: any;
      jump?: boolean;
    } = {
      className: mergeClassNames(
        "inline-block py-3 px-8 font-bold antialiased",
        className
      ),
      external,
      isGilead,
      tracking,
      jump,
    };

    if (openInNewTab) {
      attributes["target"] = "_blank";
      attributes["rel"] = "noopener noreferrer";
    }

    return attributes;
  };

  return (
    <Link to={to} {...htmlAttributes()}>
      {children}
    </Link>
  );
};

export default ButtonBase;
