import React, { FunctionComponent } from "react";
import AssetCard from "../../components/AssetCard/AssetCard";
import { useStaticQuery, graphql } from "gatsby";
import AssetCardProps from "../../components/AssetCard/AssetCard.type";

const ScreeningGuidelines: FunctionComponent<AssetCardProps> = ({
  className,
  tracking,
}) => {
  const data = useStaticQuery(graphql`
    query {
      chbScreeningGuidelines: file(
        relativePath: {
          eq: "hepatitis-b-resources/"
        }
      ) {
        publicURL
      }
    }
  `);
  return (
    <AssetCard
      className={className || "lg:w-1/3"}
      to={data.chbScreeningGuidelines.publicURL}
      headline="Chronic Hepatitis B Screening &amp; Management Brochure"
      content="An overview of the importance of CHB screening and management, including recommendations to simplify management and improve care of chronic hepatitis B patients."
      linkText="Download the Brochure"
      tracking={tracking}
    />
  );
};
export default ScreeningGuidelines;
