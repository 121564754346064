import React, { FunctionComponent } from "react";

const AssetCardGroup: FunctionComponent = ({ children }) => {
  return (
    <div className="flex flex-col -mx-4 lg:flex-row lg:flex-wrap my-4 md:my-0">
      {children}
    </div>
  );
};

export default AssetCardGroup;
