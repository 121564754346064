import React, { FunctionComponent, useEffect } from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Slab from "../components/Slab";
import { useStaticQuery, graphql, Link } from "gatsby";
import Subheading from "../components/Subheading/Subheading";
import Paragraph from "../components/Paragraph";
import AssetCardGroup from "../components/AssetCard/AssetCardGroup";
import ScreeningGuidelines from "../partials/AssetCards/ScreeningGuidelines";
import UnmetNeeds from "../partials/AssetCards/UnmetNeeds";
import AsianInfographic from "../partials/AssetCards/AsianInfographic";
import AfricanInfographic from "../partials/AssetCards/AfricanInfographic";
import CodeFinder from "../partials/AssetCards/CodeFinder";
import LivingWithHepBBrochure from "../partials/AssetCards/LivingWithHepBBrochure";
import MultilingualEducation from "../partials/AssetCards/MultilingualEducation";
import AbbrList from "../components/Abbr/AbbrList";
import Abbr from "../components/Abbr/Abbr";
import Card from "../components/Card/Card";
import Strong from "../components/Strong";
import ButtonSecondary from "../components/Buttons/ButtonSecondary";
import { ReactComponent as PlusIcon } from "../images/icons/plus-outline.svg";
import HepBManagementGuidance from "../partials/AssetCards/HepBManagementGuidance";
import tracker from "../tracker";

const ChbEducationalResources = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(
        relativePath: { eq: "hepatitis-b-screening-diagnosis/hero.jpg" }
      ) {
        ...HeroQuery
      }
      page: trackingJson(id: { eq: "hepatitis-b-resources" }) {
        subject
      }
      downloadTheGuide: trackingJson(id: { eq: "downloadTheGuide" }) {
        ...TrackingQuery
      }
      viewFlashcard: trackingJson(id: { eq: "viewFlashcard" }) {
        ...TrackingQuery
      }
      viewInfographicAsian: trackingJson(id: { eq: "viewInfographicAsian" }) {
        ...TrackingQuery
      }
      viewInfographicAfrican: trackingJson(
        id: { eq: "viewInfographicAfrican" }
      ) {
        ...TrackingQuery
      }
      downloadPdf: trackingJson(id: { eq: "downloadPdf" }) {
        ...TrackingQuery
      }
      findOutMore: trackingJson(id: { eq: "findOutMore" }) {
        ...TrackingQuery
      }
      downloadPatientBrochure: trackingJson(
        id: { eq: "downloadPatientBrochure" }
      ) {
        ...TrackingQuery
      }
      visitWebsite: trackingJson(id: { eq: "visitWebsite" }) {
        ...TrackingQuery
      }
    }
  `);
  useEffect(() => {
    window.dataLayer.push(data.page);
  }, []);

  return (
    <Layout>
      <SEO
        title="Hepatitis B Resources"
        description="Find hepatitis B videos, brochures, infographics and other resources for both healthcare providers and patients."
      />
      <Slab className="border-t border-grey-medium">
        <div className="ml-2">
          <h1 className="text-white font-bold antialiased padded-multi-line-black">
            CHB Educational Resources
          </h1>
        </div>
        <Paragraph>
          Here you'll find professional chronic hepatitis B (CHB) resources for
          you and educational materials to download and share with your
          patients.
        </Paragraph>
      </Slab>
      <Slab className="bg-grey">
        <Subheading>Information for healthcare professionals</Subheading>
        <AssetCardGroup>
          <ScreeningGuidelines tracking={tracker(data.downloadTheGuide)} />
          <UnmetNeeds tracking={tracker(data.viewFlashcard)} />
          <AsianInfographic tracking={tracker(data.viewInfographicAsian)} />
          <AfricanInfographic tracking={tracker(data.viewInfographicAfrican)} />
          <CodeFinder tracking={tracker(data.downloadPdf)} />
        </AssetCardGroup>
        <AbbrList className="pb-8">
          <Abbr
            term="AASLD"
            title="American Association for the Study of Liver Diseases"
          />
          <Abbr
            term="ACP"
            title="American
          College of Physicians"
          />
          <Abbr term="CDC" title="Centers for Disease Control and Prevention" />
          <Abbr term="USPSTF" title="U.S. Preventive Services Task Force." />
        </AbbrList>
        <Card className="bg-green">
          <div className="p-4 pb-8">
            <PlusIcon />
            <Strong color="white" size="lg" className="block py-4">
              Interested in learning about a treatment option for your patients
              with chronic Hep B?
            </Strong>
            <ButtonSecondary
              to="https://www.vemlidyhcp.com"
              external
              isGilead
              tracking={tracker(data.findOutMore)}
            >
              Find Out More
            </ButtonSecondary>
          </div>
        </Card>
      </Slab>
      <Slab className="mb-32">
        <Subheading level={3} pad={false}>
          Information for your patients
        </Subheading>
        <Paragraph>
          Help support your CHB patients with information about the hepatitis B
          virus and how to manage chronic hepatitis B.
        </Paragraph>
        <AssetCardGroup>
          <LivingWithHepBBrochure
            tracking={tracker(data.downloadPatientBrochure)}
            className="md:w-1/2"
          />
          <MultilingualEducation
            tracking={tracker(data.visitWebsite)}
            className="md:w-1/2"
          />
        </AssetCardGroup>
      </Slab>
    </Layout>
  );
};
export default ChbEducationalResources;
