import React, { FunctionComponent } from "react";
import AssetCard from "../../components/AssetCard/AssetCard";
import { useStaticQuery, graphql } from "gatsby";
import AssetCardProps from "../../components/AssetCard/AssetCard.type";

const CodeFinder: FunctionComponent<AssetCardProps> = ({
  className,
  cta,
  tracking,
}) => {
  const data = useStaticQuery(graphql`
    query {
      icd10CodeFinder: file(
        relativePath: { eq: "hepatitis-b-resources/icd-10-code-finder.pdf" }
      ) {
        publicURL
      }
    }
  `);
  return (
    <AssetCard
      className={className || "lg:w-1/3"}
      to={data.icd10CodeFinder.publicURL}
      headline="ICD-10 Code Finder"
      content="A select list of hepatitis B diagnosis codes."
      linkText={cta || "Download the PDF"}
      tracking={tracking}
    />
  );
};
export default CodeFinder;
