import React, { FunctionComponent, Children } from "react";
import Footnotes from "../Footnotes";
import Abbr from "./Abbr";

type Props = {
  children: JSX.Element[];
  className?: string;
};
const AbbrList: FunctionComponent<Props> = ({ className, children }) => {
  const separator = "; ";
  return (
    <Footnotes className={className}>
      {Children.map(children, (abbr, idx) =>
        idx === 0 ? (
          abbr
        ) : (
          <>
            {separator}
            {abbr}
          </>
        )
      )}
    </Footnotes>
  );
};
export default AbbrList;
